import React, { useContext } from "react";
import { Button } from './../../../../../../buttons/index';
import { TrustBox } from './../../../../../trust-pilot-handler/index';

import AudienceContext from "../../../../../../../contexts/audienceContext";
import RichText, { parseTag } from './../../../../../../../lib/contentful/rich-text';
import SearchIcon from './../../../../../../../lib/fontawesome/SearchIcon';

import Accordion from './../../../../../../accordion/index';
import AccordionItem from './../../../../../../accordion/item/index';

import FeatureList from './../../../../../../features/list';

import * as styles from './index.module.scss';

export const BannerCardContentLeft = ({ 
  id,
  backgroundImage,
  description,
  tableCellsMinimumAmountOfCharactersPerLine,
  title,
  titleHtmlTag,
  content,
  buttonUrl,
  buttonText,
  template,
  features,
  contentTextAlign
}) => {
  const { activeAudience } = useContext(AudienceContext);

  let css = '';
  
  if (typeof id != 'undefined' && tableCellsMinimumAmountOfCharactersPerLine > 0) {
    css += `
    #banner-block-left-col-${id} th,
    #banner-block-left-col-${id} td {
      min-width: ${tableCellsMinimumAmountOfCharactersPerLine}ch;
    }`;
  }

  let slugifiedAudience = activeAudience?.toLowerCase().replace(" ", "_");

  return (
    <>
      {template === 'Trustpilot' && <TrustBox />}

      {template === 'Accordion' &&
      
        <div id={`product-info-accordion`} className={styles.productInfoAccordion}>
            <div className={styles.productInfoAccordionHeader}>
                <div className={styles.productInfoAccordionHeaderWrap}>
                    <h2>Product Specification</h2>
                </div>
            </div>
            <Accordion hasMetaScopeAttributes simplifiedGreen>
              <AccordionItem title={'Product Information'} hasMetaScopeAttributes simplifiedGreen>
                <span>
                  {title !== null && parseTag(titleHtmlTag, title, styles.title)}
                  {content !== null && RichText(content)}
                </span>
              </AccordionItem>
            </Accordion>
        </div>        
      }

      {template === 'Trustpilot - Full Width' && <TrustBox full={true} />}

      {template !== 'Accordion' &&
        <>
          {title !== null && parseTag(titleHtmlTag, title, styles.title)}
          {content !== null && RichText(content)}
        </>
      }

      {(template === 'Product Description' && description) && (
        <>
          { RichText(description) }
          <CtaBlock contentTextAlign={contentTextAlign} template={"Product"} buttonText={"See documentation"} buttonUrl={`#product_literature-${slugifiedAudience}`} />
        </>
      )}

      {(template === 'Product Features' && features && features.length > 0 ) && (
        <FeatureList features={features} />
      )}

      <CtaBlock
        color={(backgroundImage !== null) ? `clear` : `green`}
        template={template}
        buttonText={buttonText}
        buttonUrl={buttonUrl}
        contentTextAlign={contentTextAlign}
      />

      <style>
        {css}
      </style>
    </>
  );
}

const CtaBlock = ({
  template,
  buttonText,
  buttonUrl,
  color,
  contentTextAlign
}) => {
  if (
    !(
      (buttonUrl && buttonUrl.length > 0) ||
      (buttonText && buttonText.length > 0)
    )
  )
    return null;

  let justifyBtn = 'flex-start';
  
  if (contentTextAlign === 'Center') justifyBtn = 'center';
  else if (contentTextAlign === 'Right') justifyBtn = 'flex-end';

  if (template === 'Form' || template === 'Footer Form')
    return (
      <form action={buttonUrl} method="GET" className={styles.form}>
        <input className={styles.input} type="text" name="value" placeholder={buttonText} />
        <button className={styles.submit} type="submit">
          <SearchIcon fill='#95C11F' title='Search' className={styles.icon} />
        </button>
      </form>
    );
  else if (buttonText && buttonText.length > 0)
    return (
      <div
        className={styles.btn}
        style={{
          justifyContent: justifyBtn
        }}
      >
        <Button 
          colorData={color}
          typeData={`default`}
          href={buttonUrl}
          content={buttonText}
          alt={buttonText}
          elementType={[
            'Trustpilot',
            'Accordion',
            'Form',
            'Product'
          ].includes(template) ? 'link' : 'span'}
        />
      </div>
    );
  else
    return null;
}